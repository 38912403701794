<template>
  <main>
    <!-- breadcrumb-area -->
    <section class="breadcrumb-area-two breadcrumb-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="breadcrumb-content text-center">
              <h2 class="title">{{}}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- breadcrumb-area-end -->
    <section class="fly-next-area">
      <div class="container">
        <div class="page-actions">
          <div class="page-action-item action-share">
            <div class="d-flex align-items-center">
              <a class="text-primary facebook" @click="shareOnFacebook">
                <i class="ph ph-facebook-logo"></i>
              </a>
              <a class="text-primary twitter" @click="shareOnTwitter">
                <i class="ph ph-twitter-logo"></i>
              </a>
              <a class="text-primary whatsapp" @click="shareOnWhatsApp">
                <i class="ph ph-whatsapp-logo"></i>
              </a>
              <a class="text-primary telegram" @click="shareOnTelegram">
                <i class="ph ph-telegram-logo"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-35">
          <div class="col-md-8">
            <div class="section-title">
              <router-link to="/archive" class="sub-title" style="color: #0d6efd"><i class="fa fa-angle-left" style="margin-right: 5px"></i>{{ $t('Archive') }}</router-link>
            </div>
          </div>
          <div class="col-md-4">
            <div class="best-price text-end">
            </div>
          </div>
        </div>
        <slider :slug="this.$route.query.slug" ref="mucomp" v-if="renderMyComp"></slider>
        <div class="tab-content">
          <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="news-tab" tabindex="0">
            <div class="row fly-next-active justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in newsPost" :key="item.id">
                <div class="fly-next-item">
                  <div class="fly-next-thumb">
                    <router-link :to="{ path: '/old_news_detail', query: { slug: item.id,categoryDate: $route.query.categoryDate } }">
                      <img alt="" class="bvi-img fixed-image-list" v-if="!item.image" src="../../../assets/img/images/placeholder_size_2.jpg">
                      <img class="bvi-img fixed-image-list" v-else :src="'https://api.railway.kg/media/news/full/'+item.image" alt="">
                    </router-link>
                  </div>
                  <div class="fly-next-content">
                    <span>{{ formatDate(item.add_date) }}</span>
                    <div style="height: 100px;">
                      <h4 class="title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center mt-4">
                <li class="page-item">
                  <a class="page-link"
                     href="#"
                     @click="changePageNews(newsCurrentPage - 1)">
                    {{ $t('Previous') }}
                  </a>
                </li>
                <li class="page-item" v-for="pageNumber in pageNumberNews" :key="pageNumber" :class="{active:newsCurrentPage == pageNumber || pageNumber === '....'}">
                  <a class="page-link"
                     href="#" @click="changePageNews(pageNumber)">{{pageNumber}}</a>
                </li>
                <li class="page-item">
                  <a class="page-link"
                     href="#"
                     @click="changePageNews(newsCurrentPage +1)">
                    {{ $t('NextNext') }}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";
import i18n from "@/local18";
import ArchiveSlider from "@/Views/Pages/Archive/ArchiveSlider.vue";
export default {
  data() {
    return {
      currentUrl:window.location.href,
      renderMyComp: true,
      News:[],
      itemPerPage:8,
      newsCurrentPage:1,
    };
  },
  components:{
    "slider": ArchiveSlider,
  },
  computed:{
    newsPost(){
      const startPage= (this.newsCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.News.slice(startPage, endPage);
    },
    totalNews(){
      return Math.ceil(this.News.length/this.itemPerPage)
    },
    pageNumberNews(){
      let pageNumbers = []
      if (this.totalNews<=7){
        for(let i = 1;i<=this.totalNews;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.newsCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalNews];
        }
        else if (this.newsCurrentPage<=this.totalNews-3){
          pageNumbers = [1,"....",this.newsCurrentPage-2,this.newsCurrentPage-1,this.newsCurrentPage,this.newsCurrentPage+1,this.newsCurrentPage+2,"....",this.totalNews];

        }
        else {
          pageNumbers = [1,"....",this.totalNews-4,this.totalNews-3,this.totalNews-2,this.totalNews-1,this.totalNews]
        }
      }
      return pageNumbers;
    },
  },
  setup() {
    return {getLocaleText};
  },
  created() {
    this.fetchNews();
  },
  methods: {
    shareOnTwitter (){
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnFacebook(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnWhatsApp(){
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    shareOnTelegram(){
      window.open(`tg://msg_url?url=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    changePageNews(page){
      if(page >=1 && page <= this.totalNews){
        this.newsCurrentPage = page;
      }
    },
    async fetchNews() {
      try {
        const local = i18n.global.locale.value === 'kg' ? 'ky':'ru';
        const response = await axios.get(`newsold/?page=1&page_size=10000&search=${local}`);
        this.News = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    getMainImage(images){
      let mainImage=images.find(item =>item.main_image);
      if(mainImage){
        return mainImage.image_size_2
      }
      else{
        return images[0].image_size_2
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Bu tarih dilimine göre tarihi biçimlendirir
    },
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.fetchNews();
      }
    },
    '$route.query.slug': {
      immediate: true,
      handler() {
        this.renderMyComp = false;
        this.$nextTick(() => {
          this.renderMyComp = true;
        })
      }
    }
  }
}
</script>